import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  ContactForm
} from "@fishrmn/fishrmn-components";

import emailCallback from "../utils/catering-email.js";
import AWS from "aws-sdk";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>Cristobal Mexican Grill &amp; Bar | Catering</title>
          <meta name="description" content="Check out our page for great catering prices. We will cater every occasion from birthday parties to office events." />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[0]]}
              showMap={false}
              ctas={[data.ctas.catering, data.ctas.phone]}
              ctaInverted={false}
              ctaColor={"secondary"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={false}
              fullWidth={true}
              parallax={false}
              containerAs={"outline"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <ContactForm
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true
                  },
                  {
                    label: "phone",
                    placeholder: "Phone",
                    type: "input",
                    required: true
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    required: true
                  },
                  {
                    label: "message",
                    placeholder: "Message",
                    type: "textarea",
                    required: true
                  }
                ]}
                buttonText={"Send"}
                centeredHeaders={false}
                className={""}
                fluidButton={true}
                header={"Contact Us With Any Catering Questions"}
                inline={false}
                onSubmit={emailCallback}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            catering {
              link
              text
              internal
            }
            phone {
              link
              text
              internal
            }
          }
          heroImages {
            url
          }
          businessName
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          heroImages {
            url
          }
          galleryImages {
            url
          }
        }
      }
    }
  }
`;
